#root{
  min-height: 100%;
  width: 100%;
}
html .page {
  overflow: scroll;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}

.main-page-element{
  background-color: white;
  padding: 24px;
  margin: 24px;
  height: fit-content;
  box-shadow: 0px 2px 25px 0px #e6e6e6;
  min-height: 400px;
}
@media only screen and (max-width: 600px) {
  .main-page-element{
    background-color: white;
    padding: 2px;
    margin: 1px;
    height: fit-content;
    box-shadow: 0px 2px 25px 0px #e6e6e6;
    min-height: 400px;
   /* width: 100%; */
  }
}
.main-page-elementflow2{
  background-color: white;
  padding: 24px;
  margin: 24px;
  height: fit-content;
  box-shadow: 0px 2px 25px 0px #e6e6e6;
  min-height: 400px;
}
@media only screen and (max-width: 600px) {
  .main-page-elementflow2{
    background-color: white;
    padding: 30px;
    margin: 1px;
    height: fit-content;
    box-shadow: 0px 2px 25px 0px #e6e6e6;
    min-height: 400px;
   /* width: 100%; */
  }
}
.DOBGender{
  background-color: white;
  width: 64rem;
  margin-top: 100px;
  margin-left: 20px;
  padding: 30px
}
.container{
  height: 100%;
  width: 100%;
  margin-top: 75px;
  display: flex;
  flex-direction: row;
}
#Header {
  width: 100%;
  background-color: white;
  overflow: hidden;
  line-height: 75px;
  position: fixed;
  display: flex;
  top: 0;
  box-shadow: 0 2px 6px 0 rgba(177, 177, 177, 0.5);
  z-index: 10;
}

@media only screen and (max-width: 600px) {
  #Header{
    width: 100%;
    margin-top: -13px;
  }
}
#menu {
  margin-top: 10%;
  width: 100%;
  background-color: rgb(255, 214, 178) ;
  overflow: hidden;
  line-height: 75px;
  position: fixed;
  display: none;
  top: 0;
  box-shadow: 0 2px 6px 0 rgba(177, 177, 177, 0.5);
  z-index: 10;
}

@media only screen and (max-width: 600px) {
  #menu{
  margin-top: 15.9%;
  width: 100%;
  height: 8%;
  background-color: white;
  overflow: hidden;
  line-height: 75px;
  position: fixed;
  display: block;
  top: 0;
  box-shadow: 0 2px 6px 0 rgba(177, 177, 177, 0.5);
  z-index: 10;
  }
}

@media only screen and (max-width: 854px) {
  #menu{
    margin-top: 52px;
    width: 100%;
    height: 8%;
    background-color: white;
    overflow: hidden;
    line-height: 75px;
    position: fixed;
    display: block;
    top: 0;
    box-shadow: 0 2px 6px 0 rgba(177, 177, 177, 0.5);
    z-index: 10;
    }
  }
.homeH1text{
  text-align: center;
  font-size: 14px;
}
@media only screen and (max-width: 600px) {
  .homeH1text{
    text-align: center;
    font-size: 14px;
  }
}


.homemiddletext{
 background-color: #F5F7FF;
 padding: 10px;
}
@media only screen and (max-width: 600px) {
  .homemiddletext{
    background-color: #F5F7FF;
    font-size: 20px;
  }
}
.homemiddle{
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .homemiddle{
    background-color:#F5F7FF;
    text-align: justify;
    font-size: 20px;
    
    /* font-family: 'Times New Roman'; */
    /* font-display: inherit; */
  }
}
.headerfaphone{
  transform: scaleX(-1);
  color: #f07722;
}
@media only screen and (max-width: 600px) {
  .headerfaphone{
    transform: scaleX(-1);
    color: #f07722;
    margin-right: 0px;
    /* display: none; */
  }
}
.headerfafaiconmenu{
  display: none;
}
@media only screen and (max-width: 600px) {
  /* .headerBTN:not(:first-child) {display: none;} */
  /* .headerLink:not(:first-child) {display: none;} */
  .headerBTN{
    display: none;
  }
  .headerfafaiconmenu{
    display: none;
    color: #F07722;
    font-size: 30px;
    margin-right: 3px;
  }
  
}
@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .headerfafaiconmenu{
    position: absolute;
    right: 0;
    top: 0;
  }
  .Header.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}
.headerBTN{
  border: 1px solid #f07722;
  background-color: white;
  color: #f07722;
  padding-left: 20px;
  padding-right: 25px;
  font-size: small;
  font-weight: 900;
  line-height: 15px;
  margin: 0 30px;
  display: none;
}
/* @media only screen and (max-width: 600px) {
  .headerBTN{
    border: 1px solid #f07722;
    background-color: white;
    color: #f07722;
    padding-left: 5px;
    padding-right: 15px;
    display: none;
    font-size: 9px;
    font-weight: 5px;
    line-height: 8px;
    margin: 0 8px;
  }} */
.headerBTN:hover{
  background-color: white;
  cursor: pointer;
}
/* .headerBTN :first-child{
  margin: 0 5px;
  size: 50px;
} */

.headerLink{
  text-decoration: none;
  color: #f07722;
  font-weight: normal;
  /* font-size: 10px; */
  /* padding-left: 10px;
  padding-right: 3px; */
  margin-right:70px;
}
@media only screen and (max-width: 600px) {
  .headerLink{
    text-decoration: none;
    color: #f07722;
    font-weight:normal;
    line-height: 12px;
    /* display: flex; */
    /* font-size: 10px; */
    margin-right: 10px;
  }}
#headerElement1{
  margin-left: 3%;
}
#headerElementmenu{
  margin-left: 0%;
}
@media only screen and (max-width: 600px) {
  #headerElementmenu{
    margin-left: 0px;
  background-color: #FFD6B2;
  }
}
#headerElement1 img{
  vertical-align: middle;
  height: 50px;
}
@media only screen and (max-width: 600px) {
  #headerElement1 img{
   vertical-align: middle;
   height: 35px;
  }}


#headerElement2{
  display: flex;
  position: absolute;
  right: 0;
}
@media only screen and (max-width: 600px) {
  #headerElement2{
    display: flex;
    position: absolute;
    right: 0;
  }}
#sidebar{
  width:16%;
  margin: 0;
  order: 2;
  float: right;
  min-height: 100%;
  background-color: white;
}
@media only screen and (max-width: 600px) {
  #sidebar{
    width:16%;
    height: 20%;
    margin: 0;
    display: none;
    order: 2;
    float: left;
    min-height: 100%;
    background-color: white;
  }}
  
.landingdiv{
  width: 70%;
  margin:auto;
}
@media only screen and (max-width: 600px) {
  .landingdiv{
    width: 100%;
    margin:auto;
  }
}
.landingh5{
  background-color: #F5F7FF;
}
@media only screen and (max-width: 600px) {
  .landingh5{
    text-align: center;
  }
}
.App-link {
  color: #09d3ac;
}
button {
  background-color:#f07722;
  color: white;
  font-size: x-large;
  outline: none;
  border: none;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 22px;
}

@media only screen and (max-width: 600px) {
  button {
    background-color:#f07722;
    color: white;
    font-size: xx-large;
    outline: none;
    border: none;
    padding: 10px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 22px;
    margin-bottom: 7px;
  }
  }
  .btn{
      background-color:#f07722;
      color: white;
      font-size: x-large;
      text-align: center;
      width: 92%;
      outline: none;
      border: none;
      padding: 10px;
      padding-left: 40px;
      padding-right: 40px;
      /* padding-top: 90px; */
      padding-bottom: 10px;
      border-radius: 22px;
      margin: 50px 50px 0 0;
   
  }
  @media only screen and (max-width: 600px) {
    .btn {
      background-color:#f07722;
      color: white;
      font-size: xx-large;
      width: 93%;
      outline: none;
      border: none;
      padding: 15px!important;
      /* padding-bottom: -15px;
      padding-left: 10px;
      padding-right: 30px;
      border-radius: 22px; */
      margin-bottom: 7px;
      margin-left: 13px;
    }
    }
    .btnflow2{
      background-color:#f07722;
      color: white;
      font-size: x-large;
      outline: none;
      border: none;
      padding: 10px;
      margin-bottom: -28px;
      margin-top: 10px;
      margin-left: 20px;
      margin-right: 20px;
      padding-left: 40px;
      padding-right: 40px;
      border-radius: 22px;
   
  }
  @media only screen and (max-width: 600px) {
    .btnflow2 {
      background-color:#f07722;
      color: white;
      font-size: xx-large;
      outline: none;
      border: none;
      padding: 15px!important;
      padding-bottom: -10px;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 22px;
      margin-bottom: 50px;
      margin-right: -7px;
      margin-left: -5px;

    }
    }
button:hover{
  background-color: #f74c08;
}
button:disabled{
  color: white;
  background-color:#f2bb94;
}
input{
  background-color: #f5f1ed;
  border: none;
  outline: none;
  padding: 10px;
  font-style: oblique;
  text-align: center;
  border-radius: 10px;
}
input::placeholder{
  font-style: normal;
  text-align: center;
  color: #bfbebd;
}
.error{
  border:2px solid red;
}
[type=radio] { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type=radio] + img {
  cursor: pointer;

}

/* CHECKED STYLES */
[type=radio]:checked + img {
  outline: 2px solid #f07722;
  
}
.adviser-tracker-container{
  background-color: rgb(255, 214, 178) ;
  padding: 10px 20px;
}
@media only screen and (max-width: 600px) {
  .adviser-tracker-container{
    background-color: #ffd6b2 ;
    padding: 10px 20px;
    position: relative;
    /* display: none; */
  }
  }
.adviser-catogery-tracker{
  text-align: right;
  color: #F5802C;
  border-left: 1px dashed;
  padding-left: 20px;
  border-left-width: 2px;
}
@media only screen and (max-width: 600px) {
  .adviser-catogery-tracker{
    text-align: right;
  color: #F5802C;
  border-top: 1px dashed;
  padding-left: 20px;
  border-top-width: 2px;
  }
  }

.adviser-tracker-value{
  margin: 3px 0;
  display: block;
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .adviser-tracker-value{
    width: 100%;
    text-align: left;
    color: #F5802C;
    display: block;
    font-weight: bold;
    margin: 5px 0;
    /* display: none; */
    font-size: 16px;
    
  }
  }

.adviser-tracker-circle{
  height: 13px;
  width: 13px;
  border: 1px solid  #F5802C;
  border-radius: 50%;
  float: left;
  margin-left: -28px;
  margin-top: -20px;
  top: 0;
}
@media only screen and (max-width: 600px) {
  .adviser-tracker-circle{
    height: 4px;
    width: 4px;
    border: 1px solid  #F5802C;
    border-radius: 50%;
    float: left;
    margin-left: -7px;
    margin-top: -10px;
    background-color: rgb(255, 214, 178);
    top: 0;
  }
  }
.letsbegin{
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .letsbegin{
    text-align: center;
    /* font-size: 40px; */
  }
  }

.adviser-tracker-circle-main{
  height:15px;
  width:15px;
  border: 1px solid  #F5802C;
  border-radius: 50%;
  float: left;
  margin-left: -29px;
  margin-top: -20px;
  background-color: rgb(255, 214, 178);
  top: 0;
}
@media only screen and (max-width: 600px) {
  .adviser-tracker-circle-main{
    height:10px;
    width: 10px;
    border: 1px solid  #F5802C;
    border-radius: 50%;
    float: left;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgb(255, 214, 178);
    top: 0;
  }
  }
.main-page{
  width: 84%;
  order: 1;
}
@media only screen and (max-width: 600px) {
  .main-page{
    width: 100%;
    order: 1;
  }
  }
  .nameh1{

    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
    font-size: 25px;
  }
  @media only screen and (max-width: 600px) {
    .nameh1{
      padding: 10px;
      margin-block-start: 0.2em;
      margin-block-end: 0.2em;
      font-size: 25px;
    }
    }
  .namep{
    padding: 10px;
    font-weight: bolder;
    font-size: 20px;
  }
  
  @media only screen and (max-width: 600px) {
    .namep{
      padding: 10px;
      font-weight: bolder;
      font-size: 20px;
    }
    }
    
  .nameinput{
    width:48%;
    font-weight: bold;
    font-size: 30px;
    max-width: 48%;
 }
  @media only screen and (max-width: 600px) {
    .nameinput{
      width:80%;
      font-weight: bold;
      font-size: 35px;
      max-width: 80%;
   }
    }
    .namediv{
      width: 250px;
      position: absolute;
      background-color: #FFC15C;
      bottom: 0;
      right: 0;
      border-radius: 5px;
      padding: 45px 35px 35px 35px;
    }
    @media only screen and (max-width: 600px) {
      .namediv{
        width: 250px;
        position: absolute;
        background-color: #FFC15C;
        bottom: 25px;
        right: 38px;
        border-radius: 5px;
        padding: 22px 17px 17px 17px;
        
      }
      }
      .namebtn{
        background-color: #FFC15C;
        color: white;
        font-size: 15px;
        outline: none;
        border: none;
        padding: 0;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
        position: absolute;
        top: 10%;
        right: 10%;
        cursor: pointer;
      }
      @media only screen and (max-width: 600px) {
        .namebtn{
          background-color: #FFC15C;
          color: white;
          font-size: 15px;
          outline: none;
          border: none;
          padding: 0;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 10px;
          border-radius: 0;
          position: absolute;
          top: 5%;
          right: 5%;
          cursor: pointer;
        }
        }
.namehelpbtn{
background-color: #FFDB5C;
position: absolute;
bottom: -15;
right: 0;
border-radius: 15px;
font-size: 12px;
color: black;
cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .namehelpbtn{
    background-color: #FFDB5C;
    
    bottom: -5px;
    right: 0;
    border-radius: 15px;
    font-size: 15px;
    color: black;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 22px;
    /* margin-top:px; */
    }
  }
  .dobinput{
    width: 110px;
    padding:20px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 5px;
    font-size: 20px;
}
  @media only screen and (max-width: 600px) {
    .dobinput{
      width: 82px;
      padding:20px;
      padding-left: 15px;
      padding-right: 15px;
      margin: 5px;
      font-size: 20px;
  }
 
    }
.cashFlow{
  width: 100%;
}
.cashFlowBar{
  background-color: #F5802C;
  margin: 50px 50px 0 0;
  min-width: 100%;
  font-weight: bold;
  font-size: 20px;
}

.cashFlowElement label{
  position: relative;
  float: left;
  width: 40%;
  text-align: left;
  padding-left: 2%;
}
@media only screen and (max-width: 600px) {
  .cashFlowElement label{
    /* position: relative; */
    float: left;
    width:100%;
    text-align: center;
    font-size: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  }
.cashFlowElements{
  display: inline-block;
  width: 60%;
}
@media only screen and (max-width: 600px) {
  .cashFlowElements{
    display: inline-block;
    width: 100%;
  }
  }
.cashFlowElement{
  margin: 15px;
}
@media only screen and (max-width: 600px) {
  .cashFlowElement{
    margin: 15px;
  }
  }
.cashFlowElement input{
  width: 50%;
}
@media only screen and (max-width: 600px) {
  .cashFlowElement input{
    text-align: center;
    font-size: 35px;
    width: 90%;
  }
  }
  @media only screen and (max-width: 600px) {
    .cashflownext{
      margin: 20px;
      padding: 10px;
      
    }
    }
    .cashflownet{
      font-weight: bold;
      font-size: 20px;
      width: 25%;
      /* margin-bottom: -110px; */
    }
    @media only screen and (max-width: 600px) {
      .cashflownet{
        font-weight: bold;
        font-size: 20px;
        width: 25%;
        padding-top: 10px;
        margin-left: 7px;
        /* margin-top: 110px; */
      }
      }
      @media only screen and (max-width: 1000px) {
        .cashflownet{
          font-weight: bold;
          font-size: 20px;
          width: 25%;
          padding-top: 10px;
          margin-left: 7px;
          /* margin-top: 110px; */
        }
        }
    .cashflowvalue{
    font-weight: bold;
    width: 25%;
    padding: 10%;
    }
    @media only screen and (max-width: 600px) {
      .cashflowvalue{
        font-weight: bold;
        width: 25%;
        margin-left: 7px;
      }
      }
    
  .cashFlow2Bar{
    background-color: #F5802C;
    margin: 50px 50px 0 0;
    min-width: 100%;
    font-weight: bold;
    font-size: 20px;
  }
  
  @media only screen and (max-width: 600px) {
    .cashFlow2Bar{
      background-color: #F5802C;
      /* margin: 50px 50px 0 0; */
      width: 104%;
      font-weight: bold;
      font-size: 20px;
      padding-top: 10px;
      margin-top: 50px;
      margin-left: -24px;
      /* padding-top: 10px; */
    }
    }
    .cashFlow2assetBar{
      background-color: #F5802C;
      margin: 50px 50px 0 0;
      min-width: 100%;
      font-weight: bold;
      font-size: 20px;
    }
    @media only screen and (max-width: 600px) {
      .cashFlow2assetBar{
        background-color: #F5802C;
        margin: 0 0 0 0;
        width: 100%;
        padding: 5px;
        margin-left: -24px;
        margin-top: -30px;
        font-weight: bold;
        font-size: 20px;
      }
      }
      .cashflow2flex{
        display: flex;
      }
      @media only screen and (max-width: 600px) {
        .cashflow2flex{
          display: inline;
          /* width: fit-content; */
          /* width: 100%; */
          
        }
        }
        .cashflowtotalasset{
          font-weight: bold;
          font-size: 20px;
        }
        @media only screen and (max-width: 600px) {
          .cashflowtotalasset{
            font-weight: bold;
            font-size: 35px;
            margin-bottom: -10px;
            /* padding-bottom: 10px; */
          }
          }
          .cashflowtotalassetlable{
            font-weight: bold;
            font-size: 20px;
            width: 25px;
          }
          @media only screen and (max-width: 600px) {
            .cashflowtotalassetlable{
              font-weight: bold;
              font-size: 20px;
              width: 25px;
              margin-bottom: 10px;
              line-height: 10%;
              /* padding-bottom: 10px; */
            }
            }
.cashFlow2{
  flex:100%;
  padding:10px;
  margin:10px;
}
@media only screen and (max-width: 600px) {
  .cashFlow2{
    flex:100%;
    width: 100%;
    margin-bottom: 10px;
    /* padding-bottom: 10px; */
    /* padding:2px;
    margin:px; */
  }
  }
  .cashFlow2div{
    background-color: rgb(255, 214, 178);
    height: 35px!important;
    /* width: 1x00px; */
  }
  @media only screen and (max-width: 600px) {
    .cashFlow2div{
      background-color: rgb(255, 214, 178);
      height: 30px;
      width: 103%;
      font-size: 20px;
      margin-right: 25px;
      margin-left: -25px;
      text-align: center;
      /* width: 120px; */
    }
    }
    .cashflownet1{
      font-weight: bold;
      font-size: 20px;
    }
    @media only screen and (max-width: 600px) {
      .cashflownet1{
        font-weight: bold;
        font-size: 20px;
        margin-top: 70px;
      }
      }
    .cashFlow2divmid{
      background-color: rgb(255, 214, 178);
      height: 35px;
      text-align: center;
      padding-top: 10px;
      /* width: 100px; */
    }
    /* @media only screen and (max-width: 600px) {
      .cashFlow2divmid{
        background-color: rgb(255, 214, 178);
        height: 25px;
      }
      } */
    @media only screen and (max-width: 600px) {
      .cashFlow2divmid{
        background-color: rgb(255, 214, 178);
        height: 35px;
        width: 104%;
        font-size: 20px;
        margin-right: 25px;
        margin-left: -25px;
        text-align: center;
      }
      }
    .cashFlow22div{
      background-color: rgb(255, 214, 178);
      height: 25px;
      /* width: 100px; */
    }
    @media only screen and (max-width: 600px) {
      .cashFlow22div{
        background-color: rgb(255, 214, 178);
        height: 25px;
        font-size: 7.6px;
        width: 117px;
      }
      }
.cashFlow2Element{
  margin: 15px;
}
@media only screen and (max-width: 600px) {
  .cashFlow2Element{
    margin-bottom: 30px;
  }
  }
.cashFlow2Elements{
  display: inline-block;
  width: 100%;
}
.cashFlow2Element label{
  position: relative;
  float: left;
  width: 65%;
  text-align: left;
  padding-left: 2%;
  padding: 10px;

}
@media only screen and (max-width: 600px) {
  .cashFlow2Element label{
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    font-size: 25px;
    padding-left: 2%;
    padding: 10px;
    margin-top: 10px;
    margin-left: -15px;
  }
  }
  @media only screen and (max-width: 1000px) {
    .cashFlow2Element label{
      position: relative;
      float: left;
      width: 100%;
      text-align: center;
      font-size: 25px;
      padding-left: 2%;
      padding: 10px;
      margin-top: 10px;
      margin-left: -15px;
    }
    }
    
  
.cashFlow2Element input{
  width: 25%;
}
@media only screen and (max-width: 600px) {
  .cashFlow2Element input{
    width: 90%;
    margin-left: -10px;
    font-size: 20px;
  }
  }
  @media only screen and (max-width: 1000px) {
    .cashFlow2Element input{
      width: 90%;
      margin-left: -10px;
      font-size: 20px;
    }
    }
  .cashflow2toatal{
    font-weight: bold;
    width: 25%;
    font-size: 20px;
  }
  @media only screen and (max-width: 600px) {
    .cashflow2toatal{
      font-weight: bold;
      width: 25%;
      font-size: 20px;
    }
    }
    .meethelmi{
      font-size: 15px;
    }
    @media only screen and (max-width: 600px) {
      .meethelmi{
        font-size: 20px;
      }
      }

    .meetlink{
        color: black;
        font-size: 20px;
        font-weight: bold;
        text-decoration: none;
      }
      @media only screen and (max-width: 600px) {
        .meetlink{
          color: black;
          font-weight: bold;
          font-size: 19px;
          text-decoration: none;
        }
        }
.informationBtn{
  font-size: small;
  text-align: center;
  margin-top: 2px;
  float: right;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 100%;
  margin-right: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .informationBtn{
    font-size: small;
    text-align: center;
    margin-top: 1px;
    float: right;
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 100%;
    margin-right: 20px;
    cursor: pointer;
  }
  }
.informationBtn1{
  background-color: black;
  color: #f07722;
  font-size: x-large;
  text-align: center;
  margin: 1px;
  float: right;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 6px;
  border-radius: 100%;
  margin-right: 20px;
  margin-top: -3px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .informationBtn1{
    font-size: x-large;
    color: #f07722;
    margin: 1px;
    float: right;
    padding: 15px;
    padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
    border-radius: 100%;
    margin-right: 3px;
    margin-top: 5px;
    cursor: pointer;
  }
  }
  
.informationBtn2{
  background-color: black;
  color: #f07722;
  font-size: x-large;
  text-align: center;
  margin: 1px;
  float: right;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 100%;
  margin-right: 20px;
  margin-top: -3px;
  margin-bottom: -70px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .informationBtn2{
    font-size:x-large;
    margin: 1px;
    float: right;
    padding: 15px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 3px;
  padding-bottom: 3px;
    border-radius: 100%;
    margin-right: 3px;
    margin-top: 7px;
    cursor: pointer;
  }
  }

.informationBox{
  width:30%;
  margin:10px;
  position:absolute;
  visibility:hidden;
  background-color:rgb(255, 214, 178)
}
.information-close-btn{
  font-size: small;
  margin: 10px;
  padding: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 10%;
  margin-right: 20px;
  cursor: pointer;
}
.thanksdiv{
  padding: 15px;
}
@media only screen and (max-width: 600px) {
  .thanksdiv{
    width: 90%;
    /* padding-top: 50x; */
    /* margin-top: 160px; */
   
  }
  }

@media only screen and (max-width: 600px) {
  .thanksp{
    font-size: 20px;
    text-align: center;
    margin-top: 30px;
  }
  }
  
.thanksmessage{
  color: #4ABC96;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .thanksmessage{
    color: #4ABC96;
    font-size: 25px;
  }
  }
  @media only screen and (max-width: 600px) {
    .thanksbottom{
      margin-top: 50px;
      margin-bottom: 120px;
    }
    }
 