* {
    box-sizing: border-box;
  }
  body {
    margin: 0;
  }
  /* Style the header */
  /* Style the top navigation bar */
  .topnav {
    overflow: hidden;
    background-color: #FFD6B2;
  }
  /* Style the topnav links */
  .topnav a {
    float: left;
    display: block;
    color: #F28D46;
    font-weight: bold;
    text-align: center;
    padding: 0px 29px;
    font-size: 16px;
    text-decoration: none;
  }
  @media screen and (max-width: 450px) {
    .topnav a {
      float: left;
      display: block;
      color: #F28D46;
      font-weight: bold;
      text-align: center;
      padding: 0px 14px;
      text-decoration: none;
    }
  }
  .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #F5802C;
    font-size: 50px;
    color: #fff;
    /* margin-top:30px; */
    
  }
  #dashed {
    color: #F5802C;
    border-bottom: 2px dashed;
    padding-bottom: 7px;
    border-bottom-width: 2px;
}

